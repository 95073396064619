export default function getFormattedDate(inputDate: string): string {
  const date = new Date(inputDate);
  if (Number.isNaN(date.getTime())) {
    return 'Invalid date';
  }

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
}
