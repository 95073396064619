//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SfLink from '@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue';
import { focus } from '@storefront-ui/vue/src/utilities/directives';

export default {
  name: 'SfButton',
  directives: {
    focus,
  },
  inject: {
    components: {
      default: { SfLink },
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) => ['button', 'submit', 'reset'].includes(value),
    },
    link: {
      type: [String, Object],
      default: null,
    },
    variant: {
      type: String,
      default: 'default',
      validator: (value) =>
        ['default', 'secondary', 'light', 'light-alternative', 'gray'].includes(
          value
        ),
    },
    ariaLabel: {
      type: String,
      default: 'button',
    },
    provider: {
      type: String,
    },
    providerOnlyIcon: {
      type: Boolean,
    },
  },
  linkActive(link, disabled) {
    return link && disabled;
  },
  buttonActive(link, disabled) {
    return !link && disabled;
  },
};
