






















































































import { addBasePath } from '~/helpers/addBasePath';
import { SfImage } from '@storefront-ui/vue';
import {
  computed, useContext, useRouter, toRef, PropType, defineComponent,
} from '@nuxtjs/composition-api';
import { ISbStoryData } from '@storyblok/js/dist/types';
import DownloadButton from '~/components/DownloadButton.vue';
import getFormattedDate from '~/helpers/getFormattedDate';
import { resolveUrl } from '~/modules/storyblok/helpers/resolveUrl';

export type BlogArticleTileType = 'article' | 'file';

export default defineComponent({
  name: 'BlogArticleTile',
  components: {
    DownloadButton,
    SfImage,
  },
  props: {
    article: {
      type: Object as PropType<ISbStoryData>,
      default: () => {},
    },
    type: {
      type: String as PropType<BlogArticleTileType>,
      default: 'article',
    },
  },
  setup(props: { article: ISbStoryData, type: BlogArticleTileType }) {
    const article = toRef(props, 'article');
    const { type } = props;
    const router = useRouter();
    const { localePath } = useContext();
    const linkToArticle = computed(() => {
      const slug = resolveUrl(article.value.slug);

      return localePath(`/blog/${slug}`);
    });

    const pushToUrl = () => {
      router.push(linkToArticle.value);
    };

    const noImageBg = 'https://a.storyblok.com/f/223062/450x270/c6dafecf94/no-tile-image.png';
    const isFile = type === 'file';

    const getBgFilename = () => (article.value?.content?.preview?.cached_url ? article.value.content.preview.cached_url : noImageBg);

    return {
      isFile,
      pushToUrl,
      addBasePath,
      linkToArticle,
      getFormattedDate,
      bgFilename: computed(getBgFilename),
      secondaryTag: computed(() => (article.value?.content?.secondaryTag !== '' ? article.value.content.secondaryTag : 'Blog Agrosimex')),
      data: computed(() => (article.value?.content?.wpPublishDate || article.value?.first_published_at || article.value?.created_at)),
    };
  },
});
