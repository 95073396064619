

















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { SfImage } from '@storefront-ui/vue';
import SfButton from '~/components/StorefrontUi/SfButton.vue';

export default defineComponent({
  name: 'DownloadButton',
  components: {
    SfImage,
    SfButton,
  },
  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      default: 'POBIERZ',
    },
  },
  setup({ url }) {
    const pushToUrl = () => {
      window.open(url, '_blank');
    };

    return {
      pushToUrl,
      addBasePath,
    };
  },
});
